//
// Border
//

$border-sizes: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

@each $size in $border-sizes {
    .border-#{$size} {
        border-width: #{$size}px !important;
    }
}