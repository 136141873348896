//
// Sizing
//

$sizes-v: (25, 50, 75, 100);

@each $size-v in $sizes-v {
    .vw-#{$size-v} {
        width: #{$size-v}vw !important;
    }
    .vh-#{$size-v} {
        height: #{$size-v}vh !important;
    }
    .min-vw-#{$size-v} {
        min-width: #{$size-v}vw !important;
    }
    .min-vh-#{$size-v} {
        min-height: #{$size-v}vh !important;
    }
}