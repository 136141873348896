//
// Floating objects
//


.floating-objects {
    position: absolute;
    top: 0;
    left: -20px;
    right: 0;
    width: calc(100% + 20px);
    height: 100%;
    overflow: hidden;

    span {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background-color: $primary;
    }
}