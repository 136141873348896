//
// Animations
//

.fadeIn {
    opacity: 1;
    transition: opacity .5s ease;
}

.fadeOut {
    opacity: 0;
    transition: opacity .5s ease;
}