//
// Opacity
//

$opacity: (0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100);

@each $i in $opacity {
    .opacity-#{$i} {
        opacity: #{$i / 100} !important;
    }
}