//
// Hover
//

@mixin focus() {
    &:focus {
        @content;
    }
}

@mixin active() {
    &:active,
    &.active {
        @content;
    }
}